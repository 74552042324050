import {Component, Inject, Vue} from 'vue-property-decorator';
import Header from "./shared/Header.vue";

@Component({
    components: {
        Header
    },
})
export default class AppComponent extends Vue {

}