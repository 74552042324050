import Vue from 'vue'
import VueRouter from 'vue-router'
import {Authority} from "../shared/Authority";
const Home = () => import('../views/Home.vue');
const EvaluationList = () => import('../views/ProjectCode/EvaluationSearch.vue');
const EvaluationForm = () => import('../views/ProjectCode/evaluation-form.vue');
const FundingTypeForm = () => import('../views/ProjectCode/funding-type-form.vue');
const SurveyTypeForm = () => import('../views/ProjectCode/survey-type-form.vue');
const FundingTypeList = () => import('../views/ProjectCode/funding-type.vue');
const SurveyTypeList = () => import('../views/ProjectCode/survey-type.vue');
const SurveyFundingPercentForm = () => import('../views/ProjectCode/survey-funding-percent-form.vue');
const SurveyFundingPercentList = () => import('../views/ProjectCode/survey-funding-percent.vue');
const MoreOptions = () => import('../views/ExitingAndEnforcement/MoreOptions.vue');
const ExitingInfo = () => import('../views/ExitingAndEnforcement/ExitingInfo.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/evaluation-list',
    name: 'EvaluationList',
    component: EvaluationList,
    meta: {
      title: "Project Code Search",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/evaluation-form',
    name: 'EvaluationForm',
    component: EvaluationForm,
    meta: {
      title: "New Project Code",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/funding-type-search',
    name: 'FundingTypeList',
    component: FundingTypeList,
    meta: {
      title: "FundingType Search",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/survey-type-search',
    name: 'SurveyTypeList',
    component: SurveyTypeList,
    meta: {
      title: "SurveyType Search",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/funding-type-form',
    name: 'FundingTypeForm',
    component: FundingTypeForm,
    meta: {
      title: "New FundingType",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/survey-type-form',
    name: 'SurveyTypeForm',
    component: SurveyTypeForm,
    meta: {
      title: "New SurveyType",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/survey-funding-percent-form',
    name: 'SurveyFundingPercentForm',
    component: SurveyFundingPercentForm,
    meta: {
        title: "New SurveyFundingPercentForm",
        role: Authority.ROLE_NAME
    }
  },
  {
    path: '/survey-funding-percent-search',
    name: 'SurveyFundingPercentList',
    component: SurveyFundingPercentList,
    meta: {
        title: "SurveyFundingPercent Search",
        role: Authority.ROLE_NAME
    }
  },
  {
    path: '/MoreOptions',
    name: 'MoreOptions',
    component: MoreOptions,
    props: true,
    meta: {
      title: "More Options",
      role: Authority.ROLE_NAME
    }
  },
  {
    path: '/exiting-info',
    name: 'ExitingInfo',
    component: ExitingInfo,
    meta: {
      title: "Project Exiting Information",
      role: Authority.ROLE_NAME
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Jira Project Code Management';

  next();
})

export default router
