import {Component, Inject, Vue} from "vue-property-decorator";

@Component({

})
export default class HeaderComponent extends Vue {

    public drawer: any = false
    public group: any = null

    mounted(): void {
    }

    login(): void {
        this.$keycloak.login();
    }

    logout(): void {
        console.log(process.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL);
        this.$keycloak.logout({ redirectUri : process.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL });
    }

}